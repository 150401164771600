import React, { useState } from 'react';
import {
  Box,
  Heading,
  Button,
  Text,
  keyframes,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import confetti from 'canvas-confetti';

const bounceIn = keyframes`
  0% { transform: scale(0); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const TrappedGamePage = () => {
  const [clickedCount, setClickedCount] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const handleClick = () => {
    setClickedCount(clickedCount + 1);

    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      shapes: ['circle'], 
      colors: ['#ff69b4', '#ff1493', '#ff85c0'], 
    });

    if (clickedCount >= 3) {
      onOpen();
    }
  };

  const handleCloseGame = () => {
    navigate('/dashboard');
  };

  return (
    <Box
      bg="blackAlpha.900"
      color="white"
      minH="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      p={4}
    >
      <Heading
        as="h1"
        fontSize="4xl"
        mb={6}
        color="yellow.400"
        animation={`${bounceIn} 1s ease`}
      >
        Welcome to Nunya's Surprise!
      </Heading>
      <Text fontSize="2xl" mb={6} color="gray.300">
        How much do I love you? Keep clicking to find out, Mister Doom!
      </Text>

      <Button
        onClick={handleClick}
        colorScheme="yellow"
        size="lg"
        mb={4}
        _hover={{ transform: 'scale(1.05)' }}
        animation={`${bounceIn} 1s ease`}
      >
        Click Me!
      </Button>

      <Text fontSize="xl" color="gray.400">
        You've clicked {clickedCount} times. {clickedCount < 4 ? 'Keep going!' : 'You know you wanna find out!'}
      </Text>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white">
          <ModalHeader>It's a Trap!</ModalHeader>
          <ModalBody>
            <Text>LOL, you've fallen right for my trap! There aren't enough clicks in the world to cover how much I love you.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="yellow" onClick={handleCloseGame}>
              Close and Return
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TrappedGamePage;
